<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    name: 'App',
    metaInfo: {
      title: 'Team Pages',
      titleTemplate: '%s',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },
    computed: {
      ...mapState('app', ['vibeUserRead']),
    },
    watch: {
      $route: {
        handler () {
          if (this.$route.name !== 'Login') localStorage.lastUrl = this.$route.name
        },
      },
    },
    async created () {
      if (!localStorage.vibeUser || !localStorage.vibeDirectory) if (this.$route.name !== 'Login') this.$router.push({ name: 'Login' })
      this.$gapi.listenUserSignIn(async (isSignedIn) => {
        this.isSignedIn = isSignedIn
        if (isSignedIn) {
          this.loadingLogin = false
          this.$gapi.getGapiClient().then(x => x.client.people.people.get({
            resourceName: 'people/me',
            personFields: 'names,emailAddresses,phoneNumbers,addresses,organizations',
          }).then(res => {
            if (!localStorage.signatureBgColor) localStorage.signatureBgColor = '#440099'

            if (!localStorage.logoColor) localStorage.logoColor = '#6db640'

            this.$vuetify.theme.themes.light.signatureBg = '#440099'
            this.$vuetify.theme.themes.light.logo = localStorage.logoColor || '#6db640'
            const resLocal = JSON.parse(res.body)

            this.$gapi.getGapiClient().then(x => x.client.people.people.listDirectoryPeople({
              readMask: 'names,occupations,locales,organizations,phoneNumbers,emailAddresses',
              sources: 'DIRECTORY_SOURCE_TYPE_DOMAIN_PROFILE',
              mergeSources: 'DIRECTORY_MERGE_SOURCE_TYPE_CONTACT',
            }).then(res => {
              const resDirectoryLocalTemp = JSON.parse(res.body)
              const resDirectoryLocal = resDirectoryLocalTemp ? resDirectoryLocalTemp.people.filter((x) => x.names && x.emailAddresses) : []
              localStorage.vibeDirectory = JSON.stringify(resDirectoryLocal)

              const loggedInUser = resDirectoryLocal.find(x => x.resourceName === resLocal.resourceName)
              localStorage.vibeUser = JSON.stringify(loggedInUser)
              this.saveVibeUser(loggedInUser)

              this.saveVibeDirectory(resDirectoryLocal)
            }))
            if (this.$route.name === 'Login') {
              const lastUrl = localStorage.lastUrl && localStorage.lastUrl !== 'null' ? localStorage.lastUrl : 'Home'
              this.$router.push({ name: lastUrl })
            }
          }))
        } else {
          if (localStorage.vibeUser && localStorage.vibeDirectory) {
            if (!localStorage.signatureBgColor) localStorage.signatureBgColor = '#440099'

            if (!localStorage.logoColor) localStorage.logoColor = '#6db640'

            this.$vuetify.theme.themes.light.signatureBg = localStorage.signatureBgColor || '#440099'
            this.$vuetify.theme.themes.light.logo = localStorage.logoColor || '#6db640'

            if (localStorage.vibeUser) {
              this.saveVibeUser(JSON.parse(localStorage.vibeUser))
            }
            if (localStorage.vibeDirectory) {
              this.saveVibeDirectory(JSON.parse(localStorage.vibeDirectory))
            }
          } else {
            if (this.$route.name !== 'Login') {
              localStorage.lastUrl = this.$route ? this.$route.name : 'Home'
              this.$router.push({ name: 'Login' })
            }
          }
        }
      })
    },
    methods: {
      ...mapActions('app', [
        'vibeUserWrite', 'contacts',
      ]),
      keysrt (key) {
        return (a, b) => {
          if (a[key] > b[key]) return 1
          if (a[key] < b[key]) return -1
          return 0
        }
      },
      filterPhones (phones) {
        const workPhone = phones.find(x => x.type === 'work')
        if (workPhone) return workPhone

        return phones[0]
      },
      filterOrganizations (organizations) {
        const correctTitle = organizations.find(x => x.metadata.source.type === 'DOMAIN_PROFILE')
        if (correctTitle && correctTitle.title) return correctTitle.title
        const primaryTitle = organizations.find(x => x.metadata.primary)
        if (primaryTitle && primaryTitle.title) return primaryTitle.title

        return ''
      },
      filterNames (names) {
        const correctNameGroup = names.find(x => x.metadata.source.type === 'DOMAIN_PROFILE')
        if (correctNameGroup) return correctNameGroup

        return {}
      },
      filterEmails (emails) {
        const correctEmailGroup = emails.find(x => x.metadata.source.type === 'DOMAIN_PROFILE')
        if (correctEmailGroup) return correctEmailGroup

        return {}
      },
      saveVibeUser (resLocal) {
        this.vibeUserWrite({
          dark: false,
          callout: '',
          color: 'primary',
          fullName: resLocal.names && resLocal.names.length > 0 ? this.filterNames(resLocal.names).displayName : '',
          firstName: this.filterNames(resLocal.names).givenName,
          lastName: this.filterNames(resLocal.names).familyName,
          phone: resLocal.phoneNumbers && resLocal.phoneNumbers.length > 0 ? this.filterPhones(resLocal.phoneNumbers).canonicalForm : '',
          email: resLocal.emailAddresses && resLocal.emailAddresses.length > 0 ? this.filterEmails(resLocal.emailAddresses).value : '',
          website: 'vibemovesyou.com',
          linkedIn: '',
          companyNickname: 'vibe moves you',
          companyName: 'Operated by NewMotoAsset GmbH',
          companyAddress: 'Richard-Strauss-Straße 32, A-1230 Wien',
          companyUID: 'ATU74116128',
          companyFN: '506831s',
          companyFB: 'Handelsgericht Wien',
          funct: resLocal.organizations && resLocal.organizations.length > 0 ? this.filterOrganizations(resLocal.organizations) : '',
          resourceName: resLocal.resourceName,
        })
      },
      saveVibeDirectory (resDirectoryLocal) {
        this.contacts(resDirectoryLocal.map(x => {
          return {
            dark: false,
            callout: '',
            color: 'primary',
            fullName: x.names && x.names.length > 0 ? this.filterNames(x.names).displayName : '',
            firstName: x.names && x.names.length > 0 ? this.filterNames(x.names).givenName : '',
            lastName: x.names && x.names.length > 0 ? this.filterNames(x.names)?.familyName : '',
            phone: x.phoneNumbers && x.phoneNumbers.length > 0 ? this.filterPhones(x.phoneNumbers).canonicalForm : '',
            email: x.emailAddresses && x.emailAddresses.length > 0 ? this.filterEmails(x.emailAddresses).value : '',
            website: 'vibemovesyou.com',
            linkedIn: '',
            companyName: 'Operated by NewMotoAsset GmbH',
            companyAddress: 'Richard-Strauss-Straße 32, A-1230 Wien',
            companyUID: 'ATU74116128',
            companyFN: '506831s',
            companyFB: 'Handelsgericht Wien',
            funct: x.organizations && x.organizations.length > 0 ? this.filterOrganizations(x.organizations) : '',
            resourceName: x.resourceName,
          }
        }).sort(this.keysrt('firstName')))
      },
    },
  }
</script>

<style lang="scss">
   .v-main { background-color: #440099 !important; }

  .extra-padding {
    padding-bottom: 96px !important;
    padding-top: 96px !important;

    @media screen and (max-width: 959px) {
      padding-top: 24px !important;
      padding-bottom: 24px !important;
    }
  }
</style>
