export default {
  vibeUser (state, data) {
    state.vibeUser = data
  },
  isLoggedIn (state, data) {
    state.isLoggedIn = data
  },
  contacts (state, data) {
    state.contactsArr = data
  },
}
