import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import Lang from 'vuejs-localization'
import VueGapi from 'vue-gapi'
import QrcodeVue from 'qrcode.vue'

// VUEX - https://vuex.vuejs.org/
import store from './store'

Lang.requireAll(require.context('./lang', true, /\.js$/))

Vue.use(VueGapi, {
  clientId: '961638749255-tujr1e7hibae31fh899c4cjclurj6te9.apps.googleusercontent.com',
  discoveryDocs: ['https://people.googleapis.com/$discovery/rest?version=v1'],
  scope: 'https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/contacts https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/directory.readonly https://www.googleapis.com/auth/user.addresses.read https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/user.emails.read https://www.googleapis.com/auth/user.gender.read https://www.googleapis.com/auth/user.organization.read https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
  prompt: 'select_account',
})

Vue.use(Lang)

Vue.component('QrcodeVue', QrcodeVue)

Vue.config.productionTip = false

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')
