// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/home/Index.vue'),
      children: [
        {
          path: '',
          name: 'Home',
          meta: { requiresAuth: true },
          component: () => import('@/views/home/Index.vue'),
        },
        {
          path: 'contacts',
          name: 'Contacts',
          component: () => import('@/views/contacts/Index.vue'),
          meta: { requiresAuth: true, src: require('@/assets/pro.jpg') },
        },
        {
          path: 'qr',
          name: 'Qr',
          component: () => import('@/views/qr/Index.vue'),
          meta: { requiresAuth: true, src: require('@/assets/pro.jpg') },
        },
        {
          path: 'login',
          name: 'Login',
          component: () => import('@/views/login/Login.vue'),
          meta: { requiresAuth: false },
        },
      ],
    },

  ],
})

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    router.app.$gapi.isSignedIn().then((isSignedIn) => {
      if (isSignedIn) {
        return next()
      } else {
        if (!localStorage.vibeUser || !localStorage.vibeDirectory) return next({ path: '/login' })
        return next()
      }
    })
    } else {
      return next()
    }
})

/**
 * After each route update
 */
// router.afterEach((to, from) => {})

export default router
