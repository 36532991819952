<template>
  <v-theme-provider :dark="dark">
    <v-container class="pa-0">
      <v-row
        :justify="justify"
        no-gutters
      >
        <div class="col-12">
          <div class="card mt-1">
            <div class="card-body">
              <div id="abc">
                <div style="width: 100%">
                  <table
                    cellspacing="0"
                    cellpadding="0"
                    border="0"
                    role="presentation"
                    style="font-size: 0px; line-height: 1.5"
                    width="100%"
                  >
                    <tbody>
                      <tr>
                        <td
                          id="b"
                          :style="`text-align:center;padding-top:7px;padding-bottom:7px;background-color: ${$vuetify.theme.themes.light.signatureBg}`"
                        >
                          <span
                            id="c"
                            :style="`font-size: 26px; font-family: Arial, sans-serif; color: ${$vuetify.theme.themes.light.logo}; font-weight: 700;`"
                          >vibe&nbsp;</span>
                          <span style="font-size: 26px; font-family: Arial, sans-serif; color: rgb(255, 255, 255); font-weight: 700;">moves you</span>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align:center;padding-top:10px;">
                          <span :style="`font-size: 14px; font-family: Arial, sans-serif; color: ${$vuetify.theme.themes.light.primary}; font-weight: 700;`">
                            {{ firstName }} {{ lastName }}{{ funct ? `, ${funct}` : '' }}
                          </span>
                        </td>
                      </tr>
                      <tr v-if="phone">
                        <td :style="`text-align:center;margin:0;padding:0;font-family:Arial, sans-serif; font-size:12px;;color:${$vuetify.theme.themes.light.primary};mso-line-height-rule:exactly; line-height:15px;`">
                          <a
                            :href="`tel: ${phone}`"
                            :style="`color: ${$vuetify.theme.themes.light.primary}; font-size: 12px; font-family: Arial, sans-serif; text-decoration: none`"
                          >
                            {{ phone }}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td :style="`text-align:center;margin:0;padding:0;font-family:Arial, sans-serif; font-size:12px;color:${$vuetify.theme.themes.light.primary};mso-line-height-rule:exactly; line-height:15px;`">
                          <a
                            :href="`mailto: ${email}`"
                            :style="`text-decoration: none;color:${$vuetify.theme.themes.light.primary};font-size:12px;font-family:Arial,sans-serif;font-weight:100;`"
                          >
                            {{ email }}
                          </a>&nbsp;|&nbsp;
                          <a
                            :href="`https://${website}?utm_source=${email}&utm_medium=email&utm_campaign=email_signature_website`"
                            :style="`text-decoration: none;color: ${$vuetify.theme.themes.light.primary};font-size:12px;font-family:Arial,sans-serif;font-weight:100;`"
                            target="_blank"
                          >
                            {{ website }}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td :style="`text-align:center;margin:0;padding:0;font-family:Arial, sans-serif; font-size:12px;color:${$vuetify.theme.themes.light.primary};mso-line-height-rule:exactly; line-height:15px;`">
                          <strong style="font-weight:600">vibe</strong> homebase
                          &nbsp;|&nbsp;
                          Brunner Straße 77-79, A-1230 Wien
                        </td>
                      </tr>
                      <tr v-if="linkedIn">
                        <td :style="`text-align:center;margin:0;padding:0;font-family:Arial, sans-serif; font-size:12px;;color: ${$vuetify.theme.themes.light.primary};mso-line-height-rule:exactly; line-height:15px;`">
                          <span style="color: #000000">
                            <a
                              :href="`https://${linkedIn}`"
                              style="text-decoration-color: #000000!important;text-decoration: none!important; color: #000000; font-size: 12px; font-family: Arial, sans-serif; font-weight: 100;"
                              target="_blank"
                            >
                              {{ linkedIn }}
                            </a>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span style="font-size: 7px;">&nbsp;</span>
                        </td>
                      </tr>
                      <tr>
                        <td :style="`text-align:center;margin:0;padding:0;font-family:Arial, sans-serif; font-size:10px;;color:${$vuetify.theme.themes.light.primary};mso-line-height-rule:exactly; line-height:12px;`">
                          <span :style="`font-size: 10px; font-family: Arial, sans-serif; ${$vuetify.theme.themes.light.primary};`">
                            <strong style="font-weight: 600">{{ companyName }}</strong>,
                            {{ companyAddress }},&nbsp;<br>
                            UID:&nbsp;{{ companyUID }}&nbsp;|&nbsp;
                            FN:&nbsp; {{ companyFN }}&nbsp;|&nbsp;
                            FB-Gericht:&nbsp;
                            {{ companyFB }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td :style="`text-align:center;margin:0;padding:0;font-family:Arial, sans-serif; font-size:10px;;color:${$vuetify.theme.themes.light.primary};mso-line-height-rule:exactly; line-height:12px;`">
                          <span :style="`font-size: 10px; font-family: Arial, sans-serif; ${$vuetify.theme.themes.light.primary};`">Der Schutz Ihrer Daten ist uns wichtig. Informationen zur Verarbeitung Ihrer Daten <br> (Art. 13 DSGVO) finden Sie unter <a
                            href="https://vibemovesyou.com?utm_source=<%= signature.email %>&utm_medium=email&utm_campaign=email_signature_legal"
                            :style="`text-decoration: none; color: ${$vuetify.theme.themes.light.primary}; font-size: 10px; font-family: Arial, sans-serif; font-weight: 100;`"
                          >vibemovesyou.com</a>.</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-row>
      <v-row
        v-if="$route.name === 'Contacts'"
        justify="center"
        no-gutters
        class="pt-6"
      >
        <vue-vcard
          org-post="A-1230"
          org-street="Richard-Strauss-Straße 32"
          org-city="Wien"
          org-country="Austria"
          :org-name="companyName"
          :first-name="firstName"
          :last-name="lastName"
          :work-phone="phone"
          :work-email="email"
          :role="funct"
          work-url="https://www.vibemovesyou.com"
          :size="200"
          style="margin-bottom: 15px;"
        />
      </v-row>
    </v-container>
  </v-theme-provider>
</template>

<script>
  // Mixins
  import Heading from '@/mixins/heading'
  import VueVcard from '../../components/utils/vue-vcard'

  export default {
    name: 'BaseSignatureCard',

    components: {
      VueVcard,
    },

    mixins: [Heading],

    props: {
      dark: Boolean,
      callout: String,
      color: {
        type: String,
        default: 'primary',
      },
      firstName: String,
      lastName: String,
      phone: String,
      email: String,
      website: String,
      linkedIn: String,
      companyName: String,
      companyAddress: String,
      companyUID: String,
      companyFN: String,
      companyFB: String,
      funct: String,
    },
  }
</script>
