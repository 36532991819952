<template>
  <v-theme-provider :dark="dark">
    <v-container class="pa-0">
      <v-row
        no-gutters
      >
        <div class="col-12">
          <div class="card mt-1">
            <div class="card-body">
              <div id="cba_sf">
                <table>
                  <tbody style="font-size:12px;line-height: 1.3;font-family:Arial,sans-serif;text-align:center;">
                    <tr>
                      <td
                        :style="`text-align:center;font-size:26px;padding:7px 0;background-color: ${$vuetify.theme.themes.light.signatureBg}`"
                      >
                        <b :style="`color: ${$vuetify.theme.themes.light.logo}; font-weight: 700;`">vibe&nbsp;</b><b
                          style="color:#ffffff"
                        >moves you</b>
                      </td>
                    </tr>
                    <tr>
                      <td style="padding-top:10px;">
                        <b :style="`font-size: 14px; color: ${$vuetify.theme.themes.light.primary}`">
                          {{ firstName }} {{ lastName }}, {{ funct }} </b>
                      </td>
                    </tr>
                    <tr>
                      <td
                        :style="`margin:0;padding:0;line-height:15px;color:${$vuetify.theme.themes.light.primary}`"
                      >
                        <span
                          style="color: inherit"
                        >{{ phone }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td
                        :style="`margin:0;padding:0;font-weight:100;color:${$vuetify.theme.themes.light.primary};`"
                      >
                        {{ email }}
                        &nbsp;|&nbsp;
                        {{ website }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        :style="`margin:0;padding:0;font-weight:100;color:${$vuetify.theme.themes.light.primary};`"
                      >
                        <b>vibe&nbsp;</b>
                        <span>homebase</span>
                        &nbsp;|&nbsp;
                        Brunner Straße 77-79, A-1230 Wien
                      </td>
                    </tr>
                    <tr>
                      <td
                        :style="`font-size:10px;padding:7px 0;color:${$vuetify.theme.themes.light.primary};`"
                        >
                        <span>NewMotoAsset GmbH, Richard-Strauss-Straße 32 | A-1230 Wien, UID: ATU74116128 | FN: 506831s | FB-Gericht: Handelsgericht Wien</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </v-row>
    </v-container>
  </v-theme-provider>
</template>

<script>
  // Mixins
  import Heading from '@/mixins/heading'

  export default {
    name: 'BaseSignatureCard',

    mixins: [Heading],

    props: {
      dark: Boolean,
      callout: String,
      color: {
        type: String,
        default: 'primary',
      },
      firstName: String,
      lastName: String,
      phone: String,
      email: String,
      website: String,
      linkedIn: String,
      companyName: String,
      companyAddress: String,
      companyUID: String,
      companyFN: String,
      companyFB: String,
      funct: String,
    },
  }
</script>
