module.exports = {
  home: 'Home',
  credentials: 'Credentials',
  triggers: 'Triggers',
  cron: 'Cron',
  rest: 'Rest',
  logs: 'Logs',
  logsProcesses: 'Processes',
  logsSteps: 'Steps',
  logsEvents: 'Events',
  pages: 'Pages',
  processes: 'Processes',
  permissions: 'Permissions',
  users: 'Users',
  roles: 'Roles',
  templates: 'Templates',
  translations: 'Translations',
  settings: 'Settings',
  frontend: 'Frontend',
  instance: 'Instance',
  smtp: 'Smtp',
  modules: 'Modules',
  validations: 'Validations',
  process: 'Process',
  local: 'Local',
  hub: 'Hub',
  gdpr: 'GDPR',
  gdprCronTrigger: 'Cron Trigger',
  gdprRestTrigger: 'Rest Trigger',
  gdprModules: 'Modules',
  gdprProcessCredentials: 'Process Credentials',
  gdprProcesses: 'Processes',
  plugins: 'Plugins',
}
