module.exports = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  JWT: 'JWT',
  MONGODB: 'Mongo DB',
  JDBC: 'JDBC',
  SMTP: 'SMTP',
  TWILIO: 'Twilio',
  OAUTH: 'OAuth',
  OAUTH2_REFRESH_TOKEN: 'OAuth',
  SLACK: 'Slack',
  DELETE: 'Delete',
  GET: 'Get',
  PATCH: 'Patch',
  POST: 'Post',
  PUT: 'Put',
  SUCCESSFUL: 'Successful',
  UNSUCCESSFUL: 'Unsuccessful',
  FAILED: 'Failed',
  IN_PROGRESS: 'In progress',
  NEW: 'New',
  ON_HOLD: 'On hold',
  CRON: 'Cron',
  REST: 'Rest',
  RUN_PROCESS: 'Run process',
  EMAIL: 'Email',
  EXECUTE_PROCESS: 'Execute Process',
  FOREACH: 'For Each',
  GROOVY: 'Groovy',
  JS: 'JavaScript',
  KOTLIN: 'Kotlin',
  SWITCH: 'Switch',
  WHILE: 'While',
  EDIT: 'Edit',
  VIEW: 'View',
  USE: 'Use',
  PROCESS_INACTIVE: 'Process Inactive',
  FRONTEND: 'Frontend',
  INSTANCE: 'Instance',
  PASTE: 'Paste from memory',
  CREATE: 'Create',
  YES: 'Yes',
  NO: 'No',
  GLOBAL_TRANSLATION: 'Global Translation',
  MODULE: 'Module',
  PROCESS: 'Process',
  PROCESS_CREDENTIAL: 'Credential',
  SETTING: 'Setting',
  TEXT_TEMPLATE: 'Text Template',
  TRIGGER_CRON: 'Trigger Cron',
  TRIGGER_REST: 'Trigger Rest',
  NUMBER: 'Number',
  STRING: 'String',
  MAX_LENGTH: 'Max Length',
  MAX_VALUE: 'Max Value',
  MIN_LENGTH: 'Min Length',
  MIN_VALUE: 'Min Value',
  BODY: 'Body',
  HEADERS: 'Headers',
  QUERY_PARAMS: 'Query Params',
  VALIDATION_RULE: 'Validation Rule',
  FLOWY: 'Flowy',
  BOOLEAN: 'Boolean',
  TIMESTAMP: 'Timestamp',
  INTEGER: 'Integer',
  FLOAT: 'Float',
  PROCESS_SETTING: 'Process Settings',
  CHECK_EMAIL_AVAILABILITY: 'Check Email Availability',
  CREATE_USER: 'Create User',
  GENERATE_API_KEY: 'Generate API Key',
  VALIDATE_API_KEY: 'Validate API Key',
  USER: 'User',
  FILE_JSON: 'File JSON',
  FILE_XML: 'File XML',
  PLUGIN: 'Plugin',
  AWS: 'AWS',
  S3: 'S3',
  LIST: 'List',
  READ: 'Read',
  UNSET_VARIABLES: 'Unset Variables',
  SECURITY: 'Security',
  DECRYPT: 'Decrypt',
  ENCRYPT: 'Encrypt',
  HANDLEBARS: 'Handlebars',
  MUSTACHE: 'Mustache',
  THYMELEAF: 'Thymeleaf',
  VELOCITY: 'Velocity',
  SCRIPT: 'Script',
  IMAP: 'Imap',
  TIMEOUT: 'Timeout',
  MARK_READ: 'Mark Read',
  MARK_UNREAD: 'Mark Unread',
  SEARCH: 'Search',
}
