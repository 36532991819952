module.exports = {
  eventPermission: 'Events permissions are inherited from the process that they trigger.',
  gdprHint: 'Only active objects are included in the following lists.',
  noExceptionMessage: 'No Exception Message',
  noExceptionStackTrace: 'No Exception Stack Trace',
  dataTooLArge: 'The selected entry is too large to display. Would you like to store it as file?',
  pluginPresent: 'This module contains plugin. Only install if you trust the source of the module!',
  saveFirst: 'Save first to use this feature!',
  addKeyFirst: 'Please add Key before opening Editor!',
}
