// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#440099',
        secondary: '#050B1F',
        accent: '#204165',
        nightBlue: '#440099',
        green: '#6db640',
        blue: '#74bfeb',
        violet: '#f879bb',
        red: '#ff3019',
        turquoise: '#31bca4',
        yellow: '#ffff00',
        white: '#ffffff',
        logo: '#440099',
        signatureBg: '#440099',
      },
      dark: {
        primary: '#440099',
        secondary: '#0B1C3D',
        accent: '#204165',
        nightBlue: '#440099',
        green: '#6db640',
        blue: '#74bfeb',
        violet: '#f879bb',
        red: '#ff3019',
        turquoise: '#31bca4',
        yellow: '#ffff00',
        white: '#ffffff',
        logo: '#440099',
        signatureBg: '#440099',
      },
    },
  },
})
