module.exports = {
  username: 'Username',
  password: 'Password',
  name: 'Name',
  status: 'Status',
  actions: 'Actions',
  active: 'Active',
  inactive: 'Inactive',
  phone: 'Phone',
  email: 'Email',
  search: 'Search',
  export: 'Export',
  from: 'From',
  to: 'To',
  action: 'Action',
  allNone: 'All/None',
  first: 'First',
  last: 'Last',
  model: 'Model',
  default: 'Default',
  registeredOn: 'Registered on',
  lastModifiedOn: 'Last modified on',
  createdOn: 'Created on',
  modifiedOn: 'Modified on',
  hour: 'Hour',
  minute: 'Minute',
  mandatory: 'Mandatory',
  response: 'Response',
  notifications: 'Notifications',
  text: 'Text',
  themes: 'Themes',
  details: 'Details',
  download: 'Download',
  instance: 'Instance',
  backendversion: 'Backend',
  ipAddress: 'IP Address',
  portNumber: 'Port Number',
  changePassword: 'Change password',
  system: 'System',
  created: 'Created',
  updated: 'Updated',
  cancellation: 'Cancel',
  print: 'Print',
  register: 'Register',
  number: 'No',
  mode: 'Mode',
  dark: 'Dark',
  fontSizes: 'Font Sizes',
  theme: 'Themes',
  none: 'None',
  successful: 'Successful',
  open: 'Open',
  failed: 'Failed',
  activeOnly: 'Active Only',
  keyType: 'Key Type',
  payload: 'Payload',
  privateKey: 'Private Key',
  signatureAlgorithm: 'Signature Algorithm',
  url: 'Url',
  maxPoolSize: 'Max Pool Size',
  host: 'Host',
  port: 'Port',
  sid: 'SID',
  token: 'Token',
  apiVersion: 'API Version',
  appId: 'APP ID',
  aud: 'AUD',
  id: 'ID',
  maxProcessingDurationInMSec: 'Max Processing Duration in MSec',
  maxProcessingDuration: 'Max Processing Duration',
  process: 'Process',
  triggerCondition: 'Trigger Condition',
  seconds: 'Seconds',
  minutes: 'Minutes',
  minutesInterval: 'Minutes Interval',
  hours: 'Hours',
  hoursInterval: 'Hours Interval',
  dayOfMonth: 'Day Of Month',
  month: 'Month',
  dayOfWeek: 'Day Of Week',
  method: 'Method',
  maxRequestSize: 'Max Request Size',
  frontEnd: 'Front end',
  searchByName: 'Search By Name',
  finishedOn: 'Finished On',
  startedOn: 'Started On',
  instanceId: 'Instance ID',
  eventId: 'Event ID',
  threadId: 'Thread ID',
  date: 'Date',
  clientId: 'Client ID',
  clientSecret: 'Client Secret',
  selectProcessFirst: 'Select process first',
  event: 'Event',
  exceptionMessage: 'Exception Message',
  exceptionStackTrace: 'Exception Stack Trace',
  stepId: 'Step ID',
  maxSimultaneousExecutions: 'Max Simultaneous Executions',
  saveProcessToEditSteps: 'Save Process To Edit Steps',
  defaultStepsName: 'Steps Name',
  properties: 'Properties',
  stepProperties: 'Step Properties',
  enabled: 'Step enabled',
  query: 'Query',
  targetObject: 'Target Object',
  credential: 'Credential',
  recordName: 'Record Name',
  triggerId: 'Trigger ID',
  type: 'Type',
  processingStartOn: 'Processing started on',
  showCache: 'Cache',
  list: 'List',
  json: 'JSON',
  async: 'Asynchronous execution',
  input: 'Input',
  output: 'Output',
  js: 'JavaScript',
  switch: 'Switch',
  collectionName: 'Collection Name',
  queryUpdate: 'Query Update',
  mailTemplate: 'Mail Template',
  variables: 'Variables',
  subject: 'Subject',
  restResponseCode: 'REST response code target object',
  targetUrl: 'Target Url',
  timeout: 'Timeout in MSec',
  body: 'Body',
  headers: 'Headers',
  queryParams: 'Query Params',
  channelId: 'Channel ID',
  message: 'Message',
  logsTtlInMSec: 'Time-to-live for logs',
  stepName: 'Step Name',
  copy: 'Copy',
  steps: 'Steps',
  filter: 'Filter',
  roles: 'Roles',
  step: 'Step',
  childStep: 'Child Step',
  permissions: 'Permissions',
  code: 'Code',
  assignable: 'Assignable',
  yes: 'Yes',
  no: 'No',
  textTemplate: 'Text Template',
  en: 'English',
  de: 'Deutsch',
  language: 'Language',
  connectionTimeout: 'Connection Timeout',
  writeTimeout: 'Write Timeout',
  template: 'Template',
  grant_type: 'Grant type',
  refresh_token: 'Refresh token',
  setPasswordUrl: 'Set Password Url',
  maxTriggerHttpThreads: 'Max Trigger Http Threads',
  maxTriggerHttpProcessingInMSec: 'Max Trigger http Processing',
  maxProcessingThreads: 'Max Processing Threads',
  permissionType: 'Permission Type',
  unit: 'Unit',
  changeableTimeout: 'Timeout',
  secondsInterval: 'Seconds Interval',
  account: 'Account',
  yourAccount: 'Your Account',
  setPassword: 'Set Password',
  confirmPassword: 'Confirm Password',
  baseUrlRest: 'Base Url Rest',
  stepType: 'Step Type',
  gdpr: 'GDPR Relevant',
  history: 'History',
  notSet: 'Not set',
  defaultLocale: 'Default Locale',
  global: 'Global',
  local: 'Local',
  est: 'Trace',
  addProcessName: 'Add Process Name',
  visualId: 'Visual ID',
  bg: 'Bulgarian',
  zh: 'Chinese (Simplified)',
  hr: 'Croatian',
  cs: 'Czech',
  da: 'Danish',
  nl: 'Dutch',
  et: 'Estonian',
  fi: 'Finnish',
  fr: 'French',
  el: 'Greek',
  hu: 'Hungarian',
  ga: 'Irish',
  it: 'Italian',
  lv: 'Latvian',
  lt: 'Lithuanian',
  mt: 'Maltese',
  pl: 'Polish',
  pt: 'Portuguese',
  ro: 'Romanian',
  ru: 'Russian',
  sr: 'Serbian',
  sk: 'Slovak',
  sl: 'Slovene',
  es: 'Spanish',
  sv: 'Swedish',
  tr: 'Turkish',
  uk: 'Ukrainian',
  validation: 'Validation',
  permission: 'Permission',
  exist: 'Exist',
  missingParams: 'Missed Required Resources',
  fileLoaded: 'File Loaded',
  scriptEngine: 'Script Engine',
  dataType: 'Data Type',
  ruleType: 'Rule Type',
  fields: 'Fields',
  fieldName: 'Field Name',
  required: 'Required',
  rules: 'Rules',
  field: 'Field',
  rule: 'Rule',
  validationRules: 'Validation Rules',
  logServerUrl: 'Log Server Url',
  logs: 'Logs',
  apiKey: 'Api Key',
  listOfCodes: 'List of HTTP codes',
  logsServer: 'Log Server',
  debug: 'Debug',
  processSettingName: 'Setting',
  UPLOAD: 'Upload',
  DOWNLOAD: 'Download',
  descriptions: 'Descriptions',
  loadDirection: 'Load Direction',
  encrypt: 'Encrypt',
  restResponseContentTargetObject: 'REST response content target object',
  enableStepLog: 'Logging',
  urlForAdmin: 'Healthcheck URL for admin',
  urlForProcessingService: 'Healthcheck URL for the processing service',
  searchByModuleName: 'Search by Module Name',
  description: 'Description',
  basicAccessAuthentication: 'Allow Basic Authentication',
  upDownload: 'Up/Download',
  remoteId: 'Remote Id',
  exception: 'Exception',
  className: 'Class Name',
  checksum: 'Checksum',
  stepTypes: 'Step Types',
  plugin: 'Plugin',
  selectPlugin: 'Select Plugin First',
  processLogs: 'Process logs',
  openProcessLogs: 'Open process logs',
  accessKeyId: 'Access Key Id',
  region: 'Region',
  s3Bucket: 'S3 Bucket',
  secretKey: 'Secret Key',
  content: 'Content',
  key: 'Key',
  path: 'Path',
  events: 'Events',
  backEnd: 'Back end',
  isCacheStepLogsEnabled: 'Cache storage',
  isStepLogsEnabled: 'Logging',
  enableCacheLog: 'Cache storage',
  tos: 'Terms of Service',
  privacyPolicy: 'Privacy Policy',
  passphrase: 'Passphrase',
  queryConsole: 'Query Console',
  console: 'Console',
  affectedRows: 'Affected Rows',
  success: 'Success',
  respondSent: 'Respond sent',
  resultDetails: 'Result Details',
  engine: 'Engine',
  isBodyScript: 'Body Script',
  groovy: 'Groovy',
  last15MinutesCount: 'Last 15 minutes',
  last24HoursCount: 'Last 24 hours',
  last60MinutesCount: 'Last 60 minutes',
  todayCount: 'Today',
  executions: 'Executions',
  eventStates: 'Events states',
  childEventIdPath: 'Child event ID target object',
  folder: 'Folder',
  sizeInBytes: 'Size In Bytes',
  readType: 'Read Type',
  maxResults: 'Max Results',
  recipient: 'Recipient',
  sender: 'Sender',
  emailId: 'Email Id',
}
