import mutations from './mutations'

// state initial values
const state = {
  vibeUser: {
    dark: '',
    callout: '',
    color: 'primary',
    fullName: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    website: '',
    linkedIn: '',
    companyNickname: '',
    companyName: '',
    companyAddress: '',
    companyUID: '',
    companyFN: '',
    companyFB: '',
    funct: '',
    Website: 'vibemovesyou.com',
    ColorTheme: 'ThemeGreenNightBlue',
    resourceName: '',
  },
  contactsArr: [],
  isLoggedIn: false,
}

const getters = {
  vibeUserRead (state) {
    return state.vibeUser
  },
  isLoggedIn (state) {
    return state.isLoggedIn
  },
  contactsArr (state) {
    return state.contactsArr
  },
}

const actions = {
  vibeUserWrite ({ commit }, data) {
    commit('vibeUser', data)
  },
  isLoggedIn ({ commit }, data) {
    commit('isLoggedIn', data)
  },
  contacts ({ commit }, data) {
    commit('contacts', data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
